@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(246, 246, 246);
}
/* NAVBAR START */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  position: relative;
  z-index: 999;
}
.home {
  margin: 10px;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;
}
.home:hover {
  background-color: #444;
}

.logo-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.logo-image {
  height: 75%;
  object-fit: contain;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;
  float: left;
  margin-right: 10px;
}
.logo-image:hover {
  background-color: lightblue;
}

.navbar-menu {
  display: flex;
  text-align: center;
}

.fa-instagram {
  margin-right: 8rem;
}
.navbar-item {
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  user-select: none;
}

.navbar-item:hover {
  background-color: #444;
}
/* NAVBAR END */

/* NEWS START */
/* Set max-width to limit the width of the container */
.news-container {
  max-width: 70rem;
  margin: 0 auto;
}

/* Style for each news card */
.eachNews-card {
  display: flex;
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.eachNews-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Style for the news image */
.newsImg {
  width: 18rem;
  border-radius: 5px;
  margin: 5px;
  height: -webkit-fill-available;
}

/* Style for the news title */
.eachNews-h1 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Style for the news date */
.newsDate {
  font-size: 12px;
  color: #999;
  margin-bottom: 0.5rem;
}

/* Style for the news description */
.description {
  margin-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.2;
  color: #555;
  overflow: hidden;
}

/* Style for the "Read More" button */
.read-btn {
  display: inline-block;
  padding: 0.3rem 1rem;
  font-size: 0.6rem;
  margin-left: 10px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #337ab7; /* change to a darker shade of blue */
  background-color: transparent;
  border: 1px solid #337ab7; /* change to match the text color */
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.read-btn:hover {
  background-color: #337ab7; /* change to match the text color */
  color: #fff;
}

.read-btn:active {
  transform: translateY(1px);
}

/* Style for the news info container */
.news-info-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Style for the news pics container */
.news-pics-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd;
  width: 24rem;
  height: 15rem;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 30px;
}

.news-pics-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.news-pics-container img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.news-pics-container:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
/* Style for the current news */
.current-news {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  text-align: center;
  color: #333;
  margin-top: 1rem;
  margin-bottom: 30px;
  text-transform: uppercase;
}

/* Style for the news header picture */
.newsheader-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  /* Style for each news card in mobile view */
  .eachNews-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
    height: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }

  .eachNews-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  /* Style for the news image in mobile view */
  .newsImg {
    width: 100%;
    border-radius: 5px;
    margin: 5px;
  }

  /* Style for the news title in mobile view */
  .eachNews-h1 {
    text-align: center;
  }

  /* Style for the news date in mobile view */
  .newsDate {
    text-align: center;
  }

  /* Style for the news info container in mobile view */
  .news-info-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

/* NEWS END */

/* STANDINGS START */

.standings-table-div {
  overflow-x: auto;
}

.standings-table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
  font-size: 14px;
  border: 1px solid #ddd;
}

.standings-table thead {
  background-color: #333;
  color: #fff;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 1;
}

.standings-table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.standings-table th,
.standings-table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.standings-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.standings-table tr:hover {
  background-color: #ddd;
  color: #333;
}

.standings-table th:first-child,
.standings-table td:first-child {
  text-align: center;
}
.standings-table td:first-child {
  position: sticky;
  left: 0;
  background-color: rgb(246, 246, 246);
}

.team-name-h1 {
  text-align: center;
}

@media (max-width: 768px) {
  .standings-table {
    font-size: 12px;
  }
}

.team-standings-name {
  color: blue;
  cursor: pointer;
  font-weight: bold;
}

.standings-table td:hover {
  background-color: #ddd;
  color: #333;
}

.standings-table th:hover {
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

/* STANDINGS END */

/* LEADERS START */

.leaders-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.leaders-card-div {
  display: flex;
  flex-direction: column;
  border: 1.5px solid lightgray;
  border-radius: 5px;
  width: 20rem;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.leaders-card-div:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.leaders-category {
  background-color: lightgray;
  margin-top: 0;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 8px;
  font-size: 1rem;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px;
}

.stats-container p {
  margin: 0;
  font-size: 14px;
  color: #444;
}
.stats-info p {
  margin: 0.2rem;
}

.player-leader-name {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.player-leader-name:hover {
  text-decoration: underline;
}

.click-to-show {
  color: rgb(153, 0, 1);
  font-size: 12px;
  text-align: right;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;
  margin-top: auto;
}

.stats-info {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.leaders-player-name {
  font-weight: bold;
  color: rgb(0, 89, 222);
  line-height: 0;
  font-size: 14px;
  margin-left: 10px;
}

.leaders-team-name {
  font-size: 12px;
  color: #888;
  margin-left: 10px;
}

.leaders-image {
  border-radius: 50%;
  border: 2px solid black;
  width: 2.4rem;
}

.team-name-h1 {
  font-size: 24px;
  text-align: center;
  margin: 20px 0;
}

.stats-info p:first-child {
  font-weight: bold;
}

@media (max-width: 768px) {
  .leaders-card-div {
    width: 100%;
    height: auto;
  }
}

.team-name-h1 {
  font-size: 1.6rem;
}

.search-player-box {
  width: 300px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #fff;
  border: none;
  border-radius: 5px;
  background-color: #333;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  margin: 0 auto;
  display: block;
}

.search-player-box:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  background-color: #444;
}

.search-player-box::placeholder {
  color: #bbb;
}

/* LEADERS END */

/* FOOTER START */
.footer-div {
  color: white;
  background-color: rgb(51, 51, 51);
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-logo {
  border-radius: 50%;
  width: 2rem;
  cursor: pointer;
}
.footer-youtube {
  width: 2rem;
  cursor: pointer;
}
.footer-instagram {
  width: 2rem;
  cursor: pointer;
}
.footer-tik-tok {
  width: 2rem;
  cursor: pointer;
}

/* FOOTER END */

/* GAMES START */
.games-div {
  width: 95%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px;
  gap: 10px;
  scrollbar-width: none;
}

.games-div::-webkit-scrollbar {
  display: none;
}

.team-score-divs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
  padding: 5px;
  max-width: 10rem;
}

.team-score-divs p {
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  padding-left: none;
}

.card {
  width: calc(50% - 10px);
  margin: 7px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 9px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.scores-table {
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  border-collapse: collapse;
}

.scores-table td,
th {
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
}

.boxscore-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}

.last-game-team-name:hover {
  text-decoration: underline;
  font-weight: bold;
}

/* GAMES END */

/* PLAYERS COMPONENT START */

.player-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.player-actual-image {
  margin-top: 2rem;
  border-radius: 5px;
  width: 9rem;
}
.player-career-table-div {
  width: 95%;
  overflow-x: scroll;
  border-top: 1px solid gray;
  margin-top: 5rem;
}
.table-headers-player td {
  font-weight: bold;
}
.player-table td {
  white-space: nowrap;
}
.player-table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
}
.player-table thead {
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
.player-table th,
.player-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.player-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.player-table tr:hover {
  /* background-color: #ddd; */
}
.player-table th:hover {
  background-color: black;
}
.player-table th:first-child,
.player-table td:first-child {
  text-align: center;
}

/* PLAYERS COMPONENT END */

.boxscore-divs {
  width: 97%;
  overflow-x: scroll;
  font-size: 12px;
}
.boxscore-tables {
  border-collapse: collapse;
  width: 100%;
}

.boxscore-tables th,
.boxscore-tables td {
  padding: 8px;
  text-align: left;
}

.boxscore-tables th {
  background-color: #444;
  color: #fff;
  font-weight: bold;
}

.boxscore-tables tr:nth-child(even) {
  background-color: #f2f2f2;
}

.boxscore-tables tr:hover {
  background-color: #ddd;
}

.boxscore-tables td {
  border: 1px solid #ddd;
}
.boxscore-divs td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
  border-right: 1px solid #ddd;
}

/* BOX SCORE START  */

/* LAST GAMES  */

.last-game-team-name {
  cursor: "pointer";
}

.last-game-team-name:hover {
  background-color: #ffff00;
  cursor: pointer;
}
.boxscore-button {
  display: inline-block;
  background-color: #0077c0;
  color: #fff;
  font-size: 7px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  margin: 0 auto;
  cursor: pointer;
}

.boxscore-button:hover {
  background-color: #005da3;
  cursor: pointer;
}
.quarter-btn-div {
  display: flex;
}

.quarter-btn-div button {
  width: calc(95% / 5);
  padding: 4px 8px;
  border: none;
  border-radius: 5px;
  background-color: #0077c0;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.quarter-btn-div button:last-child {
  margin-right: 0;
}

.quarter-btn-div button:hover {
  background-color: #005da3;
  cursor: pointer;
}
.table-div {
  overflow-x: scroll;
}
.table-div td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: rgb(246, 246, 246);
}
.table-div th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: rgb(51, 51, 51);
}

.picks-pct-div {
  background-color: #0c2340;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  gap: 1rem;
}

.picks-pct-div p {
  font-size: 1.2rem;
  text-align: center;
  margin: 0;
}

.each-pick {
  border: 1px solid #dee2e6;
  border-bottom: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.each-pick:hover {
  transform: translateY(-5px);
}

.top-each-pick {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.top-each-pick img {
  width: 2.5rem;
}

.inside-picks-div {
  display: flex;
  align-items: center;
  border-right: 1px solid;
  width: 58%;
}

.inside-picks-div p {
  margin: 0.2rem;
  font-size: 1rem;
  font-weight: 500;
}

.inside2-picks-div p {
  margin: 0.1rem;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #212529;
  color: white;
  padding: 3px;
  border-radius: 4px;
}
.experto {
  margin: 0.1rem;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #07d003;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}
.expertu {
  margin: 0.1rem;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #c50101;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}
.expert-table-headers {
  display: flex;
  background: #6c757d;
  justify-content: space-around;
  color: white;
  padding: 0.5rem 1rem;
}

.expert-table-headers p {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}
.picks-top-header {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.team-name-h1 {
  font-family: "Roboto", sans-serif;
  font-size: 1.9rem;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
}

/* GAMES COMPONENT START  */
.games-to-play-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.eachGame-to-play-div{
  margin: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eachGame-to-play-div img {
  width: 21rem;
  transition: filter 0.3s ease;
}

.eachGame-to-play-div img:hover {
  filter: brightness(50%);
}



/* GAMES COMPONENT END  */


/* GUESS THE PLAYER START */

.selectedPlayerDiv{
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* Button Styles */
.start-game-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #4CAF50;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.start-game-button:hover {
  background-color: #45a049;
}

.start-game-button:active {
  background-color: #3e8e41;
}

.guess-player-div {
  display: flex;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.choices-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background: rgba(0,0,0,0.6);
  box-shadow: 0px 0px 50px rgba(0,0,0,0.8);
}

.each-choice {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 15px;
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgb(23, 23, 97);
  /* background: linear-gradient(to bottom right, #1e3c72 0%, #2a5298 100%); */
  box-shadow: 0px 0px 20px rgba(0,0,0,0.8);
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
}

.each-choice:hover {
  transform: scale(1.02); /* slightly increase the size when hovering */
}
.correct-choice {
  background-color: green;
}

.wrong-choice {
  background-color: red;
}

.selectedPlayerDiv img {
  width: 23rem;
}

.guess-numbers-div {
  width: 89%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282c34;
  padding: 15px;
  color: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.guess-numbers-div p {
  margin: 0;
}

/* Extra style for points */
.guess-numbers-div p:nth-child(2) {
  color: #61dafb; /* Light blue text color */
  font-size: 1.5rem; /* Larger text */
  font-weight: bold; /* Bold text */
}
.guess-numbers-div p:nth-child(1) {
  color: green; /* Light blue text color */
  font-weight: bold; /* Bold text */

}
.guess-numbers-div p:nth-child(3) {
  color: red; /* Light blue text color */
  

}

/* GUESS THE PLAYER END */


