.chat-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #f8f8f8;
    font-family: Arial, sans-serif;
  }
  
  .chat-box ul {
    list-style: none;
    padding: 0;
    height: 400px;
    overflow-y: scroll;
  }
  
  .chat-box .eachMessage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    color: white;
    transition: all 0.3s ease;
  }
  
  .chat-box .eachMessage button {
    background: #666;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .chat-box .eachMessage button:hover {
    background: #555;
  }
  
  .chat-box form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: #2f2d2d;
    padding: 1rem;
    border-radius: 5px;
  }
  
  .chat-box form input[type="text"], 
  .chat-box form select {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chat-box form button {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .chat-box form button:hover {
    background: #0056b3;
  }
  .name-input {
    width: 7rem;
  }
  